// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-doctors-js": () => import("./../../../src/pages/doctors.js" /* webpackChunkName: "component---src-pages-doctors-js" */),
  "component---src-pages-emergency-js": () => import("./../../../src/pages/emergency.js" /* webpackChunkName: "component---src-pages-emergency-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-speciality-js": () => import("./../../../src/pages/speciality.js" /* webpackChunkName: "component---src-pages-speciality-js" */),
  "component---src-pages-teledoctor-js": () => import("./../../../src/pages/teledoctor.js" /* webpackChunkName: "component---src-pages-teledoctor-js" */),
  "component---src-templates-doctor-doctor-js": () => import("./../../../src/templates/doctor/doctor.js" /* webpackChunkName: "component---src-templates-doctor-doctor-js" */),
  "component---src-templates-speciality-speciality-wrapper-js": () => import("./../../../src/templates/speciality/specialityWrapper.js" /* webpackChunkName: "component---src-templates-speciality-speciality-wrapper-js" */),
  "component---src-templates-teledoctor-teledoctor-js": () => import("./../../../src/templates/teledoctor/teledoctor.js" /* webpackChunkName: "component---src-templates-teledoctor-teledoctor-js" */)
}

